import React from "react";
import DraggableButton from "./DraggableButton";
import {PageType} from "../../utils/PageType";
import {useTranslation} from "react-i18next";

interface WelcomeComponentProps {
    setPageType: (pageType: PageType) => void;
}

const WelcomeComponent : React.FC<WelcomeComponentProps> = ({ setPageType }) => {

    const { t } = useTranslation();

    return (
        <div className={"w-screen h-screen flex flex-col items-center"}>
            <img src={"./assets/images/logo.svg"} alt={"simly-logo"} className={"w-28 mt-[10%]"}/>
            <div className="w-[82.5%] mt-[7.5%] flex flex-col items-center rounded-3xl bg-gradient-to-br from-[#F0A1B780] from-20% via-[#42C98E4D] to-[#4DC6E599]">
                <img src={"./assets/images/gifts.png"} alt={"gifts"} className={"mt-[7.5%]"}/>
                <p className={"font-sharpgrotesk-bold font-semibold text-[#130F01] text-2xl mt-[15%]"}>{t('freeSim')}</p>
                <div className={"flex flex-col items-center justify-center mt-[2%] mb-[20%]"}>
                    <p className={"font-gilroy-regular font-semibold text-[#130F01] text-sm"}>{t('freeSimFirstLine')}</p>
                    <p className={"font-gilroy-regular font-semibold text-[#130F01] text-sm"}>{t('freeSimSecondLine')}</p>
                </div>
            </div>
            <div className={"w-[70%] h-[10%] mt-[10%]"}>
                <div className={"w-100 h-[80%] bg-[#222222] relative rounded-2xl flex flex-row items-center justify-center"} onClick={() => setPageType(PageType.EMAIL)}>
                    <div className={"w-[70%] flex flex-row items-center justify-center"}>
                        <p className={"font-gilroy-semibold text-lg text-white"}>{t('redeemNow')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WelcomeComponent;
