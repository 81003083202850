import {PageType} from "../../utils/PageType";
import React from "react";
import {UserInfo} from "../../dto/UserInfo";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

interface EmailComponentProps {
    setPageType: (pageType: PageType) => void;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
}

const EmailComponent : React.FC<EmailComponentProps> = ({ setPageType, userInfo, setUserInfo }) => {

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const { t } = useTranslation();

    return (
        <div className={"w-screen h-screen flex flex-col items-center"}>
            <div className={"w-[90%] flex flex-col items-start mt-[7.5%]"}>
                <div className={"w-7 h-7 rounded-full bg-[#FAFAFC] flex items-center justify-center"} onClick={() => setPageType(PageType.WELCOME)}>
                    <img src={"./assets/images/back-arrow.svg"} alt={"back"}/>
                </div>
                <div className={"flex flex-col mt-[7.5%] w-full"}>
                    <p className={"font-gilroy-bold text-3xl text-black"}>{t('enterEmailHeader')}</p>
                    <p className={"font-gilroy-semibold text-lg text-[#BFBFB9] mt-[1%]"}>{t('enterEmailDescription')}</p>
                    <input
                        className={"mt-[7.5%] text-2xl text-black font-gilroy-bold outline-none placeholder-[#BFBFB9] w-full"}
                        onChange={(e) => setUserInfo({
                            ...userInfo,
                            email: e.target.value!.trim()
                        })}
                        value={userInfo.email}
                        placeholder={t('enterEmail')}
                        type={"email"}
                        autoFocus
                    />
                </div>
                <div className={"mt-[15%] w-full flex flex-row justify-center items-center"}>
                    <div className={"flex flex-row items-center justify-center w-52 h-14 rounded-xl bg-[#E85929] select-none"} onClick={() => {
                        const isValidEmail = emailRegex.test(userInfo.email);

                        if(!isValidEmail) {
                            toast.error(t('emailIncorrect'), {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            return;
                        }

                        toast.dismiss();

                        setPageType(PageType.DESTINATION);
                    }}>
                        <p className="font-gilroy-semibold text-xl text-white">
                            {t('nextButton')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailComponent;
