import React, {useEffect} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFoundPage from "./pages/NotFoundPage";
import AppPage from "./pages/AppPage";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CodeAlreadyRedeemedPage from "./pages/CodeAlreadyRedeemedPage";
import EmailAlreadyRedeemedPage from "./pages/EmailAlreadyRedeemed";
import {I18nextProvider} from "react-i18next";
import i18n from "./translations/i18n";

function App() {
    useEffect(() => {
        const userLanguage = navigator.language || navigator.languages[0];

        console.log("Setting language to " + userLanguage);
        i18n.changeLanguage(userLanguage).then(res => {
            console.log("Set language successfully");
        });
    }, []);

  return (
      <I18nextProvider i18n={i18n}>
          <BrowserRouter>
              <Routes>
                  <Route path=":code" element={<AppPage/>} />
                  <Route path="404" element={<NotFoundPage/>} />
                  <Route path="redeemed/code" element={<CodeAlreadyRedeemedPage/>} />
                  <Route path="redeemed/email" element={<EmailAlreadyRedeemedPage/>} />
              </Routes>
              <ToastContainer />
          </BrowserRouter>
      </I18nextProvider>
  )
}

export default App;
