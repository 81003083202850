import React from 'react';
import {useTranslation} from "react-i18next";

const CodeAlreadyRedeemedPage: React.FC = () => {
    const { t } = useTranslation();

    return (
        <div className="h-screen flex flex-col items-center justify-center">
            <div className="text-center">
                <h1 className="text-6xl font-extrabold text-gray-800">SIMERR-0</h1>
                <p className="text-2xl font-semibold text-gray-600">{t('alreadyRedeemed')}</p>
                <p className="mt-2 text-gray-500">{t('qrCodeRedeemed')}</p>
            </div>
        </div>
    );
};

export default CodeAlreadyRedeemedPage;
