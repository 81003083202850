import {PageType} from "../../utils/PageType";
import React, {useEffect, useRef, useState} from "react";
import {UserInfo} from "../../dto/UserInfo";
import Sheet, {SheetRef} from "react-modal-sheet";
import {DestinationsDTO} from "../../dto/DestinationDTO";
import DestinationSectionComponent from "./DestinationSectionComponent";
import {CountryType} from "../../utils/CountryType";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {confirmAlert} from "react-confirm-alert";
import DestinationConfirmationAlert from "./DestinationConfirmationAlert";
import {use} from "i18next";
import 'react-confirm-alert/src/react-confirm-alert.css';

interface DestinationComponentProps {
    setPageType: (pageType: PageType) => void;
    userInfo: UserInfo;
    setUserInfo: (userInfo: UserInfo) => void;
}

const DestinationComponent : React.FC<DestinationComponentProps> = ({ setPageType, userInfo, setUserInfo }) => {

    const { t } = useTranslation();

    const [search, setSearch] = useState<string>("");
    const [destinations, setDestinations] = useState<DestinationsDTO>({
            local: [],
            regional: [],
            global: []
        }
    )

    const [loading, setLoading] = useState<boolean>(true);
    const [openType, setOpenType] = useState<CountryType | undefined>(undefined);
    let abortController = new AbortController();

    const ref = useRef<SheetRef>();
    const snapTo = (i: number) => ref.current?.snapTo(i);

    const sheetItems = openType !== undefined ?
        openType === CountryType.LOCAL ? destinations.local :
            openType === CountryType.REGIONAL ? destinations.regional :
                openType === CountryType.GLOBAL ? destinations.global : [] : [];

    const runSearch = async () => {
        abortController.abort();
        abortController = new AbortController();

        const resp = await fetch("https://us-central1-simly-dedfe.cloudfunctions.net/getQRPlans", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                search: search
            })
        })

        if(resp.ok) {
            const data = await resp.json();
            setDestinations({
                local: data.data.local,
                regional: data.data.regional,
                global: data.data.global
            })
        } else {
            toast.error("An error occurred whilst trying to fetch the plans");
        }
    }
    useEffect(() => {
        setLoading(true);
        runSearch().then(() => setLoading(false));

        return () => {
            abortController.abort();
        };
    }, [search]);

    return (
        <>
            <div className={"w-screen h-screen flex flex-col items-center"}>
                <div className={"w-[90%] flex flex-col items-start mt-[7.5%]"}>
                    <div className={"w-full flex flex-row items-center"}>
                        <div className={"w-8 h-8 rounded-full bg-[#FAFAFC] flex items-center justify-center"} onClick={() => setPageType(PageType.EMAIL)}>
                            <img src={"./assets/images/back-arrow.svg"} alt={"back"}/>
                        </div>
                        <p className={"ml-4 font-gilroy-bold text-2xl text-black"}>{t('chooseDestination')}</p>
                    </div>

                    <div className={"w-full h-12 mt-[8%] flex flex-row bg-[#F8F8FA] rounded-2xl items-center justify-center"}>
                        <div className={"w-[85%] flex flex-row items-center"}>
                            <img src={"./assets/images/search.svg"} alt={"search"}/>
                            <input
                                className={"ml-4 bg-[#F8F8FA] text-lg text-black font-gilroy-semibold outline-none placeholder-[#BFBFB9] w-full"}
                                onChange={(e) => setSearch(e.target.value.trim())}
                                value={search}
                                placeholder={t('searchDestination')}
                                type={"text"}
                            />
                        </div>
                    </div>

                    <DestinationSectionComponent setPageType={setPageType} userInfo={userInfo}
                                                 setUserInfo={setUserInfo} setOpenType={setOpenType} loading={loading} items={destinations.local} section={CountryType.LOCAL}/>
                    <DestinationSectionComponent setPageType={setPageType} userInfo={userInfo}
                                                 setUserInfo={setUserInfo} setOpenType={setOpenType} loading={loading} items={destinations.regional} section={CountryType.REGIONAL}/>
                </div>
            </div>
            <Sheet
                ref={ref}
                isOpen={openType !== undefined}
                onClose={() => setOpenType(undefined)}
                snapPoints={[800, 600, 400, 200, 0]}
                initialSnap={2}
                onSnap={snapIndex =>
                    console.log('> Current snap point index:', snapIndex)
                }
            >
                <Sheet.Container>
                    <Sheet.Header></Sheet.Header>
                    <Sheet.Content style={{ paddingBottom: ref.current?.y }}>
                        <Sheet.Scroller draggableAt="both">
                            <div className={"w-full bg-white flex flex-col items-center gap-5 mt-5 overflow-auto"}>
                                {
                                    sheetItems.map((country, index) => (
                                        <div key={index} className={`w-[90%] flex flex-row items-center justify-between ${index === destinations.local.length - 1 ? 'mb-5' : ''}`}>
                                            <div className={"w-[90%] flex flex-row items-center"}>
                                                <img className={"w-9 h-9"} src={country.image} alt={country.name}/>
                                                <p className={"ml-3.5 text-lg font-gilroy-semibold w-[60%] truncate"}>{country.name}</p>
                                            </div>
                                            <div className={"w-6 h-6 rounded-full bg-[#FAFAFC] flex items-center justify-center"} onClick={() => {
                                                confirmAlert({
                                                    customUI: ({ onClose }) => {
                                                        return <DestinationConfirmationAlert country={country} setPageType={setPageType} userInfo={userInfo} setUserInfo={setUserInfo} onClose={onClose}/>
                                                    }
                                                });
                                            }}>
                                                <img src={"./assets/images/forward.svg"} alt={"forward"} className={"w-3"}/>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Sheet.Scroller>
                    </Sheet.Content>
                </Sheet.Container>
                <Sheet.Backdrop />
            </Sheet>
        </>
    )
}

export default DestinationComponent;
